<template>
    <div data-cy="ruleList">
        <LList
            ref="list"
            analytics-prefix="Rule"
            :columns="columns"
            :companies="[]"
            :fetching="fetching"
            :items="rules"
            :loading="isLoading"
            model-name="Rules"
            :search-fields="searchFields"
            :tooltip-wording="tooltips"
            :total-item-count="totalRuleCount"
            @cleared-search-values="resetSearchFields"
            @fetch="fetch"
        >
            <template #action-buttons>
                <RouterLink
                    v-if="canManage"
                    :disabled="isLoading"
                    aria-label="Add Rule"
                    data-cy="goToRuleBuilderAdd"
                    class="btn btn-primary mr-0 mr-lg-2 mb-2 mb-lg-0"
                    :to="{name: 'admin.rules.builder.add', params: {policySystemId: currentPolicySystem.id}, query: {...$route.query}}"
                >
                    <FontAwesomeIcon
                        icon="plus"
                    />
                    Rule
                </RouterLink>

                <button
                    aria-label="Change Policy System"
                    class="btn btn-primary mr-0 mr-lg-2 mb-lg-0"
                    data-cy="selectPolicySystem"
                    @click="$bvModal.show('policySystemSelect')"
                >
                    <FontAwesomeIcon
                        icon="server"
                    />
                    Policy System:
                    <template v-if="currentPolicySystem">
                        {{ currentPolicySystem.title }}
                    </template>
                    <FontAwesomeIcon
                        v-else
                        icon="spinner"
                        pulse
                    />
                </button>

                <button
                    data-cy="ruleBuilderEvaluate"
                    :class="['btn', 'btn-primary']"
                    @click="showTestModal"
                >
                    <FontAwesomeIcon icon="square-terminal" />

                    Evaluate Rules
                </button>
            </template>

            <template #filter-buttons>
                <BDropdown
                    analytics-label="Action Type Dropdown"
                    analytics-location="Rules List View"
                    data-cy="actionTypeDropdown"
                    class="mx-0 mx-lg-1 mb-1 my-lg-0"
                    variant="primary"
                    :multiple="true"
                    :text="tooltipWording.searchFieldNames.actionType"
                >
                    <BDropdownItem
                        v-for="actionType in actionTypes"
                        :key="actionType.value"
                        link-class="text-wrap"
                        :active="searchFields.actionType === actionType.value"
                        :data-analytics-label="`Action Type Search Dropdown (${actionType.label})`"
                        data-analytics-location="Rules List View"
                        :value="actionType.value"
                        @click="addSearchFieldParam({key: 'actionType', value: actionType.value})"
                    >
                        {{ actionType.label }}
                    </BDropdownItem>
                </BDropdown>

                <BDropdown
                    analytics-label="Status Dropdown"
                    analytics-location="Rules List View"
                    data-cy="statusDropdown"
                    class="mx-0 mx-lg-1 my-1 my-lg-0"
                    variant="primary"
                    :multiple="true"
                    :text="tooltipWording.searchFieldNames.status"
                >
                    <BDropdownItem
                        v-for="statusType in statusTypes"
                        :key="statusType.value"
                        link-class="text-wrap"
                        :active="searchFields.status === statusType.value"
                        :data-analytics-label="`Status Search Dropdown (${statusType.label})`"
                        data-analytics-location="Rules List View"
                        :value="statusType.value"
                        @click="addSearchFieldParam({key: 'status', value: statusType.value})"
                    >
                        {{ statusType.label }}
                    </BDropdownItem>
                </BDropdown>

                <RouterLink
                    aria-label="Advanced Search"
                    data-cy="openAdvancedSearchModal"
                    class="btn btn-primary mx-0 ml-lg-1 my-1 my-lg-0"
                    :to="{name: 'admin.rules.search', params: {policySystemId: currentPolicySystem.id}}"
                >
                    <FontAwesomeIcon icon="search" />

                    Advanced Search
                </RouterLink>
            </template>

            <template #list-item-action-buttons="{ item }">
                <button
                    v-if="canManage"
                    :id="`duplicateBtn${item.id}`"
                    v-b-tooltip.hover="tooltipWording.actions.duplicate"
                    :aria-label="tooltipWording.actions.duplicate"
                    :data-cy="`duplicateRuleButton${item.id}`"
                    class="btn btn-duplicate btn-link btn-admin"
                    @click="handleDuplicateRule(item)"
                >
                    <FontAwesomeIcon
                        :icon="['far', 'clone']"
                        size="2x"
                        fixed-width
                    />
                </button>

                <RouterLink
                    :id="`optionsBtn${item.id}`"
                    v-b-tooltip.hover="tooltipWording.actions.options"
                    :aria-label="tooltipWording.actions.options"
                    class="btn btn-link btn-options btn-admin"
                    :data-cy="`optionsBtn${item.id}`"
                    :to="{name: 'admin.rules.options', params: {ruleId: item.id, policySystemId: currentPolicySystem.id}, query: {...$route.query}}"
                >
                    <FontAwesomeIcon
                        icon="cog"
                        size="2x"
                        fixed-width
                    />
                </RouterLink>
            </template>
        </LList>

        <RouterView />
    </div>
</template>

<script>
    import LList from '@imt/vue-list/src/components/LList.vue';
    import LStatusIndicator from '@imt/vue-list/src/components/LStatusIndicator.vue';
    import authMixin from '@imt/vue-toolbox/src/mixins/auth';
    import toastsMixin from '@imt/vue-toolbox/src/mixins/toasts';
    import utils from '@imt/vue-toolbox/src/utils';
    import versioningMixin from '@imt/vue-versioning/src/mixins/versioning';
    import dayjs from 'dayjs';
    import {mapActions, mapMutations, mapState} from 'vuex';

    import {tooltips} from '@/lang';
    import {disableMixin} from '@/mixins/disable';
    import permissionsMixin from '@/mixins/permissions';
    import {dropdownUtils} from '@/utilities';

    export default {
        name: 'BRRuleList',
        components: {
            LList
        },
        mixins: [
            authMixin,
            disableMixin,
            permissionsMixin,
            toastsMixin,
            versioningMixin,
        ],
        data() {
            return {
                columns: [
                    {
                        class: 'col-1 text-break',
                        key: 'title',
                        header: 'Title',
                        sortable: true,
                    },
                    {
                        class: 'col-5 text-break',
                        key: 'description',
                        header: 'Description',
                        sortable: true,
                        sortBy: 'versions__description',
                        value: item => {
                            return this.sortVersions(item)[0].description;
                        },
                    },
                    {
                        class: 'col-2',
                        key: 'published_date',
                        header: 'Published Date',
                        value: item => {
                            let date = item.policyEffectiveDate
                                ? dayjs(item.policyEffectiveDate).format('MM/DD/YYYY')
                                : dayjs(this.sortVersions(item).at(-1).publishedAt).format('MM/DD/YYYY');

                            return dayjs(date).isValid() ? date : '(not set)';
                        },
                    },
                    {
                        class: 'col-2',
                        key: 'actions',
                        header: 'Actions',
                        sortable: true,
                        sortBy: 'versions__actions__action_type',
                        value: item => {
                            return this.actionTypeDisplay(this.sortVersions(item)[0]);
                        },
                    },
                    {
                        key: 'status',
                        class: 'col-1 page-status',
                        component: LStatusIndicator,
                        sortable: false,
                        options: {
                            wording: {
                                conflict: 'A Draft is out of date with published or scheduled changes',
                                not_published: 'Not yet published',
                                published: 'Published',
                                scheduled: 'Scheduled to be published',
                                obsolete: 'Obsolete',
                                disabled: 'Disabled'
                            },
                            statusFrom: item => {
                                return this.sortVersions(item)[0];
                            },
                        },
                    },
                    {
                        class: 'col-auto options px-0',
                        key: 'options',
                    },
                ],
                fetching: false,
                isLoading: false,
                tooltipWording: tooltips.en,
            };
        },
        computed: {
            actionTypes: dropdownUtils.actionTypes,
            statusTypes: dropdownUtils.statusTypes,
            tooltips() {
                return {
                    ...this.tooltipWording,
                    search_field_names: this.tooltipWording.searchFieldNames,
                    search_field_display: {
                        actionType: value => this.tooltipWording.actionTypes[value],
                        status: value => this.tooltipWording.versioning[value],
                    },
                };
            },
            ...mapState([
                'currentPolicySystem',
                'rules',
                'searchFields',
                'totalRuleCount'
            ]),
        },
        watch: {
            async currentPolicySystem(newValue, oldValue) {
                if (newValue?.id !== oldValue?.id) {
                    this.isLoading = true;
                    await this.fetch();
                    this.isLoading = false;
                }
            },
        },
        async mounted() {
            if (this.currentPolicySystem) {
                // Only fetch rules if the user had previously cached a policy system id - otherwise, rules will
                // be fetched once the user selects a policy system.
                this.isLoading = true;
                await this.fetch();
                this.isLoading = false;
            }

            await this.initFromQuery();
        },
        methods: {
            async initFromQuery() {
                for (const [key, value] of Object.entries(this.$route.query)) {
                    if (!['page', 'perPage'].includes(key)) {
                        this.SET_SEARCH_FIELDS({
                            key: key,
                            value: value,
                        });
                    }
                }
            },
            async addSearchFieldParam(fieldObject) {
                this.SET_SEARCH_FIELDS(fieldObject);

                this.$router.push({
                    query: {...this.$route.query, [fieldObject.key]: fieldObject.value, ['page']: '1'}
                }).catch(err => {
                    utils.console.log(err);
                });

                await this.fetch();
            },
            async resetSearchFields() {
                await this.CLEAR_SEARCH_FIELDS();
                await this.$router.push({name: 'admin.rules.list'});
                await this.fetch();
            },
            disableWording(item) {
                return item.disabledAt === null ? 'Disable Rule' : 'Enable Rule';
            },
            actionTypeDisplay(version) {
                return version.actions.map(action => {
                    return this.tooltipWording.actionTypes[action.actionType];
                }).join(', ');
            },
            async fetch() {
                if (this.fetching) {
                    // prevent rules from being fetched multiple times

                    return;
                }

                this.fetching = true;

                try {
                    await this.fetchRules(this.$route.query);
                } catch (error) /* istanbul ignore next */ {
                    utils.console.log(error);
                    this.error('Failed to retrieve the list of rules.');
                } finally {
                    this.fetching = false;
                }
            },
            async handleDuplicateRule(item) {
                this.$ga.event('Rule Modal', 'Duplicate Rule', 'action');

                try {
                    let versionToDuplicate = this.sortVersions(item).find(v => !this.isReverted(v));
                    await this.duplicateRule({
                        rule: item,
                        versionId: versionToDuplicate.id,
                        policyPrefix: this.currentPolicySystem.policyPrefix,
                    });

                    this.success('Successfully duplicated rule!');
                } catch (error) /* istanbul ignore next */ {
                    this.error('Failed to duplicate rule!');
                    utils.console.log(error);
                }
            },
            showTestModal() {
                this.$router.push({name: 'admin.rules.test'});
            },
            ...mapActions([
                'fetchRules',
                'disableRule',
                'duplicateRule',
            ]),
            ...mapMutations([
                'CLEAR_SEARCH_FIELDS',
                'SET_SEARCH_FIELDS'
            ]),
        },
    };
</script>
